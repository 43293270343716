.about_main_top {
  padding: 100px 150px;
  color: white;
}

.elementor-heading-title {
  padding-bottom: 100px;
  font-size: 55px;
  width: 60%;
}

.inner_span {
  color: transparent !important; /* Makes the text invisible */
  -webkit-text-stroke: 1px white; /* Outlines the text in white (change '1px' for border thickness) */
  text-stroke: 1px white;
  font-weight: 900;
}

.about_us_img {
  background-image: url("../../../Assets/img/about_us_banner.png");
  /* color: white; */
  height: 60vh;
  width: calc(100vw);
  background-size: calc(100vw) 60vh;
  position: relative;
  right: 150px;
  z-index: 9;
  display: flex;
  justify-content: end;
}

.about_us_img > p {
  color: transparent !important; /* Makes the text invisible */
  -webkit-text-stroke: 1px white; /* Outlines the text in white (change '1px' for border thickness) */
  text-stroke: 1px white;
  position: relative;
  right: 15%;
  font-size: 100px;
  font-weight: 900;
  bottom: 20vh;
}

.about_bottom_content_top {
  display: flex;
  gap: 40px;
  padding: 40px 0;
}

.about_bottom_heading {
  font-size: 36px;
  min-width: 330px;
  margin: 0;
  font-weight: 600;
}

.about_bottom_desc {
  color: #888;
}

.about_bottom_content_bottom {
  display: flex;
  gap: 30px;
}

.about_bottom_section_desc{
    line-height: 2em;
    color: #888;
    font-size: 14px;
}

.about_bottom_section_title{
    font-size: 18px;
    font-weight: 600;
}

@media screen and (max-width:500px) {
  .elementor-heading-title{
    font-size: 28px;
    width: 90%;
  }

  .about_main_top{
    padding: 30px;
  }

  .about_us_img{
    width: 100vw !important;
    background-size: 100% 100% ;
    right: 30px !important;
    height: 300px;
  }

  .about_us_img>p{
    font-size: 36px;
    bottom: 40px;
  }

  .about_bottom_content_top{
    flex-direction: column;
    max-width: 80%;
  }

  .about_bottom_content_bottom{
    flex-direction: column;
    max-width: 80%;
  }

  .about_us_img{
    margin-left: 0 !important;
  }
}

.about_us_img{
  margin-left: 69px;
  position: relative;
  z-index: 1;
}