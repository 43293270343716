:root {
  --primary: #c9f31d;
  --secondary: #2c2c2c;
  --black: #000;
  --white: #fff;
  --gray: #c2c2c2;
  --font-family: "Kanit", sans-serif;
}

.sideNav_main_div {
  height: 70px;
  transform: rotate(90deg);
  width: 100vh;
  background-color: #001a33;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: calc(-50vh + 35px);
  z-index: 9999;
  top: calc(50vh - 35px);
}

.top_section_main_div {
  background-color: #000e2e;
  min-height: calc(100vh - 180px);
  padding-bottom: 50px !important;
  padding: 40px 80px 0 120px;
  position: relative;
  z-index: 2;
}

.deploy_div {
  color: white;
  font-weight: 700;
}

.small_deploy_text {
  font-size: 32px;
  /* margin: 0; */
}

.big_deploy_text {
  font-size: 135px;
  margin-top: 40px;
  margin-bottom: 20px;
  line-height: 100px;
}

/* .strategy_div_text{
    display: inline-block;
    max-width: 300px;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.5;
    color: white;
    padding-bottom: 15px;
    border-bottom: 1px solid white;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    overflow: hidden;
} */

.strategy_div {
  display: inline-block;
  max-width: 300px;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  color: white;
  padding-bottom: 15px;
  border-bottom: 1px solid white;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
}

.ball {
  position: fixed;
  background-color: transparent !important;
  border-radius: 50%;
  border-color: white !important;
  z-index: 999;
}

.rotate {
  animation: rotation 20s infinite both;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.top_section_bottom_div_home {
  /* margin-top: 50px; */
  display: flex;
}

.top_section_bottom_div_home > img {
  animation: up_down 2s infinite both;
  position: relative;
  top: 40px;
}

@keyframes up_down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.top_section_bottom_div_home_inner_div {
  margin-left: auto;
  position: relative;
  top: 90px;
  width: 250px;
}

.top_section_bottom_div_home_inner_div_first {
  font-weight: 400;
  font-size: 50px;
  line-height: 1.3;
  margin: 0;
}

.top_section_bottom_div_home_inner_div_second {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
  margin: 0;
}

.marquee-container {
  width: 100vw;
  overflow: hidden;
  background-color: #def1ff;
  height: 90px;
  position: relative;
  z-index: 2;
}

.marquee-content {
  display: inline-block;
  display: flex;
  align-items: center;
  gap: 70px;
  height: 100%;
  white-space: nowrap;
  animation: marquee 20s linear infinite; /* Adjust animation duration as needed */
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.main_focus_div {
  background-color: white;
  padding-left: 80px;
  height: fit-content;
  z-index: 1;
  padding-bottom: 30px;
}

.focus_div_top_heading {
  font-weight: 700;
  line-height: 60px;
  color: var(--black);
  font-size: 42px !important;
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  text-align: start;
  position: relative;
  transform: translate3d(0, 0, 0);
  opacity: 1;
  width: 70%;
  padding-left: 20%;
  z-index: 2;
}

.focus_img_div_img {
  padding: 70px 30px;
  position: fixed;
  left: 80px;
  top: 5%;
  z-index: 0;
}

.focus_img_div_img2 {
  position: fixed;
  right: 80px;
  top: 10%;
  z-index: 0;
}

.focus_div_content {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
}

.focus_div_content_text {
  width: 40%;
  padding-left: 100px;
  box-sizing: border-box;
  margin: 0;
  font-style: normal;
  position: relative;
  z-index: 1;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.4;
  color: #555;
  margin-bottom: 60px;
  max-width: 90%;
  perspective: 400px;
}

.focus_explore {
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-decoration: none;
  border: 1px solid #555;
}

.main_footer_component {
  position: relative;
  z-index: 2;
  display: flex;
  background-color: transparent;
}

.main_footer_component_first_part {
  background-color: #000e2e;
  padding: 100px 80px 80px 80px;
  margin-left: 69px;
  width: 55%;
  height: 340px;
}

.footer_text_small {
  font-size: 16px;
  font-weight: 600;
  color: #999;
}

.main_footer_component_first_part_top {
  display: flex;
  gap: 40px;
}

.main_footer_component_first_part_top > * {
  flex: 1;
}

.footer_text_title {
  color: white;
  font-weight: 600;
  font-size: 24px;
}

.main_footer_component_second_part {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 520px;
  background-color: #2e394c;
  width: 20%;
}

.focus_explore > p {
  color: #555;
  font-size: 18px;
  font-weight: 500;
}

.main_footer_component_second_part_text {
  width: 65%;
  font-weight: 600;
  font-size: 36px;
  line-height: 1.3;
  color: white;
  padding-bottom: 25px;
}

.main_footer_component_second_part_timings {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
}

.journal_main_component {
  background-color: #000e2e;
  position: relative;
  z-index: 2;
  margin-left: 69px;
  padding: 100px 80px;
}

.journal_heading {
  color: white;
  font-size: 60px;
  font-weight: 700;
}

.journal_desc {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.journal_top_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 60px;
  align-items: center;
}

.journal_top_section > * {
  flex: 1;
}

.journalcard_img {
  position: relative;
  height: 100%;
  width: 100%;
  /* z-index: 2; */
}

.journalcard_img2 {
  height: 100%;
  position: relative;
  width: 100%;
  /* right: 100%; */
  /* bottom: 100%; */
  /* bottom: 450px; */
  display: block !important;
  transition: right 1s;
}

.all_journals {
  display: flex;
  gap: 20px;
}

.all_journals > * {
  width: 33%;
}

.journalcard_main_component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.trigger::-webkit-scrollbar {
  display: none;
}

.journalcard_desc {
  color: #555;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.3;
  margin-bottom: 10px;
  text-align: center;
}

.journalcard_caption {
  color: white;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  margin: 0;
}

.clients_main_component {
  background-color: white;
  position: relative;
  margin-left: 69px;
  padding: 100px 80px;
}

.clients_top_images {
  display: flex;
  justify-content: space-between;
  padding: 0 20%;
  padding-bottom: 100px;
  align-items: center;
}

.clients_slider {
  display: flex;
  gap: 60px;
}

.slider {
  display: flex;
  /* flex-direction: column; */
  overflow: scroll;
  align-items: center;
}

.slider > * {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  /* position: absolute; */
}

.slider > * > * {
  width: 100%;
  text-align: center;
}

.slider::-webkit-scrollbar {
  display: none;
}

.main_slider_content {
  color: #000e2e;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  max-width: 750px;
  position: relative;
}

.client_name {
  color: #000e2e;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.client_designation {
  font-size: 14px;
  color: #555;
  font-weight: 400;
}

.clients_bottom_heading {
  font-size: 36px;
  color: black;
  font-weight: 600;
  width: 50%;
}

.client_companies_images {
  display: flex;
  gap: 0;
}

.horizontal_scrollbar {
  overflow: auto;
  background-color: #000e2e;
  margin-left: 69px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
}

.horizontal_scrollbar::-webkit-scrollbar {
  display: none;
}

.scrollbar1_first {
  height: 100vh;
  min-width: calc(100vw - 69px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 150px;
  font-weight: 600;
}

@keyframes scrollbarText {
  from {
    opacity: 0;
    position: relative;
    top: -40px;
  }
  to {
    opacity: 1;
    position: static;
  }
}

.scrollbar1_first_text {
  color: white;
  font-size: 100px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  width: 50%;
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation: scrollbarText 3s;
}

.scrollbar_second {
  min-width: 100vw;
  height: 100vh;
  color: white;
  padding: 100px;
  padding-top: 50px;
}

.scrollbar_second_inner_div {
  display: flex;
  gap: 200px;
}

.scrollbar_second_inner_div {
  width: 100vw;
}

.scrollbar_second_img {
  max-width: 30%;
}

.scrollbar_second_img > img {
  max-width: 100%;
}

.scrollbar_second_technologies {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  margin: 0 30px;
}

.scrollbar_second_plus_icon {
  font-size: 32px;
  font-weight: 500;
  margin-left: auto;
}

.scrollbar_second_tech_name {
  font-size: 24px;
  font-weight: 600;
}

.scrollbar_second_title {
  font-size: 24px;
  width: 150px;
  font-weight: 600;
}

.scrollbar_second_tech_desc {
  color: #555;
  padding: 0;
  margin: 0;
  font-style: normal;
  line-height: 1.5;
  font-weight: 400;
  font-size: 15px;
  position: relative;
  height: 0; /* Initial height set to 0 */
  overflow: hidden; /* Hide overflow content */
  animation: answer 2s forwards; /* Apply the animation and retain the final state */
  z-index: 1;
  transition: height 0.3s ease-in-out; /* Smooth transition for height change */
}

@keyframes answer {
  to {
    height: fit-content; /* Final height set to fit the content */
  }
}

.why_small_main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  text-wrap: wrap;
}

.why_small_content {
  width: 50%;
}

.why_small_circle {
  height: 150px;
  width: 150px;
  border: 2px solid white;
  border-radius: 50%;
  display: flex;

  justify-content: center;
  align-items: center;
}

.why_small_img {
  height: 80%;
  width: auto;
}

.why_small_title {
  font-size: 24px;
  font-weight: 600;
}

.why_small_desc {
  font-size: 16px;
  font-weight: 500;
}

.scroller_third_main {
  background-color: #2e394c;
  min-width: calc(100vw - 200px);
  height: calc(100vh);
  display: flex;
  gap: 50px;
  color: white;
  padding: 100px;
  padding-top: 20px;
}

.scroller_third_first_half {
  width: 40%;
}

.scroller_third_second_half {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  gap: 30px;
  width: calc(60% - 50px);
}

.ball_img {
  position: absolute;
  bottom: 25vh;
  right: 0%;
  z-index: 9;
  width: 80vw;
  height: auto;
}

.slide_togglers {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding-right: 40px;
}

.toggle_left {
  height: 60px;
  width: 60px;
  border: 2px solid #555;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.fullbar_main_component {
  position: fixed;
  z-index: 10000000;
  top: 50%;
  color: white;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100vw;
  background-color: #000e2e;
}

.fullbar_main_component > * {
  height: 100%;
}

.side_fullbar {
  width: 350px;
  flex: 1;
}

.main_fullbar {
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2;
  align-items: flex-start;
}

hr {
  margin: 0;
}

.right_fullbar {
  width: 300px;
  flex: 1;
}

.links {
  width: calc(100% - 100px);
  font-size: 60px;
  margin: 10px 0;
  font-weight: 600;
  margin: 0;
  padding: 10px 50px;
  color: white;
  text-decoration: none;
  border-bottom: 1px solid white;
  cursor: pointer;
}

.achievements_main_div {
  height: 300px;
  width: 100vw;
  background-color: aliceblue;
  color: #555;
  display: flex;
  justify-content: center;
  gap: 10%;
  position: relative;
  z-index: 2;
  align-items: center;
  margin-left: 69px;
}

.count_of_achievement {
  font-size: 60px;
  font-weight: 600;
  margin: 0;
  text-align: center;
}

.achievement_text {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.logo_fullbar {
  height: 35%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.logo_fullbar > a {
  font-size: 46px;
  text-decoration: none;
  font-weight: 700;
  color: white;
}

.socials_fullbar {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-left: 20%;
}

.socials_fullbar_heading {
  font-size: 26px;
  font-weight: 600;
}

.focus_explore:hover {
  transform: scale(1.1);
  background-color: #03153e;
  transition: transform 0.5s, background-color 3s;
  color: white !important;
}

.socials_fullbar_socials {
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  color: white;
  margin: 5px;
}

.side_linkss {
  display: flex;
  flex-direction: column;
  margin-left: 6%;
  margin-top: 40px;
}

.side_linkss > a {
  font-size: 18px !important;
  text-decoration: none;
  border: none !important;
  /* margin: 5px !important; */
}

.right_fullbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 490px;
  background-color: #2e394c;
}

.get_in_touch_fullbar {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-items: flex-start;
  color: white;
}

.getInTouch_title {
  font-size: 26px;
  color: white;
  font-weight: 600;
}

.getInTouch_desc {
  text-decoration: none;
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin: 10px;
}

@media screen and (max-width: 500px) {
  .focus_img_div_img,
  .focus_img_div_img2 {
    display: none;
  }
  .client_says {
    margin-left: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .journalcard_desc {
    width: 80vw !important;
  }
  .client_says_main {
    min-width: 330px !important;
  }

  .journalcard_caption {
    width: 80vw !important;
  }

  .client_says_inner_div {
    flex-direction: column;
  }

  html {
    width: 100vw;
  }
  .small_deploy_text {
    font-size: 18px;
  }
  .big_deploy_text {
    font-size: 32px;
    line-height: 36px;
  }

  .sideNav_main_div {
    width: 100vh;
    display: none;
    left: calc(-350px + 55px);
  }

  .top_section_main_div {
    padding: 30px;
  }

  .nnn_div > .big_deploy_text {
    margin: 0;
  }

  .main_focus_div {
    padding: 0;
    padding-bottom: 30px;
  }

  .focus_div_top_heading {
    font-size: 24px !important;
    line-height: 30px;
  }

  .focus_div_content {
    padding-top: 40px;
    justify-content: flex-start;
  }

  .focus_div_content_text {
    padding: 0 20px !important;
    width: 100%;
  }

  .focus_last_div {
    padding: 0 !important;
  }
  .comingsoon_main {
    margin: 0 !important;
    width: 100vw !important;
  }

  .comingsoon_main > p {
    font-size: 32px !important;
    margin-bottom: 0 !important;
  }

  .journal_main_component {
    margin: 0;
    padding: 32px;
  }

  .journal_top_section {
    width: 80vw;
  }

  .journal_top_section > * {
    width: 100% !important;
  }

  .all_journals {
    width: 80vw;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .journal_heading {
    margin: 10px;
  }

  .journalcard_img {
    min-width: 100%;
  }

  .journalcard_img2 {
    min-width: 100%;
    display: none;
  }

  .achievements_main_div {
    flex-direction: column;
  }
  .horizontal_scroll_behaviour {
    display: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .mobile_horizon {
    display: block !important;
  }

  .main_footer_component_first_part {
    padding: 20px;
  }

  .horizontal_scrollbar {
    height: fit-content !important;
    padding-bottom: 40px;
  }

  .scrollbar_second_img {
    width: 75vw;
    max-width: 75vw;
  }

  .client_says1 {
    margin-right: 0 !important;
  }

  .scrollbar_second {
    height: fit-content !important;
    padding-left: 0 !important;
  }

  .scrollbar_second_img > img {
    width: 90vw !important;
  }

  .horizontal_scrollbar,
  .scrollbar_second_technologies {
    margin: 0 !important;
  }

  .scrollbar_second_tech_desc {
    max-width: 80vw;
    margin-left: 10px;
  }

  .scrollbar_second_technologies {
    width: 75vw;
  }

  .scrollbar_second_inner_div {
    flex-direction: column !important;
    gap: 30px !important;
    justify-content: center;
    align-items: center;
  }

  .scrollbar_second_inner_div > * {
    width: 100% !important;
  }

  .scrollbar_second_tech_name {
    font-size: 18px !important;
  }

  .scrollbar_second {
    padding: 20px;
  }

  .scroller_third_main {
    padding: 0;
    height: fit-content !important;
  }

  .scroller_third_heading {
    width: 100vw !important;
    text-align: center;
  }

  .scroller_third_title {
    margin: 20px !important;
    font-size: 32px !important;
    width: 90vw !important;
  }

  .scroller_third_description {
    margin: 20px !important;
    font-size: 18px !important;
    width: 90vw !important;
  }

  .why_small_title {
    width: 90vw !important;
  }

  .why_small_desc {
    width: 90vw !important;
  }

  .scroller_third_main {
    flex-direction: column;
  }

  .scroller_third_main > * {
    width: 75%;
  }

  .why_small_main {
    flex-direction: column !important;
  }

  .why_small_main > * {
    width: 90%;
  }

  .why_small_circle {
    width: 200px;
    height: 200px;
  }

  .main_footer_component_first_part {
    padding: 0;
    margin: 20px;
    height: fit-content;
    width: 80% !important;
  }

  .main_footer_component_first_part_top {
    flex-wrap: wrap;
  }

  .main_footer_component {
    flex-direction: column !important;
    height: fit-content !important;
  }

  .main_footer_component > * {
    width: 100%;
  }

  .scrollbar1_first_text {
    font-size: 32px;
  }

  .trigger {
    width: 100vw;
    /* justify-content: center; */
  }

  .mobile_nav {
    display: flex !important;
  }

  .fullbar_main_component {
    flex-direction: column !important;
    gap: 40px;
    /* height: fit-content !important; */
    overflow: scroll;
  }

  .fullbar_main_component > * {
    max-width: 100%;
    min-height: fit-content;
  }

  .side_fullbar {
    height: 90vh;
  }

  .links {
    font-size: 32px;
    padding-left: 30px;
  }

  .main_fullbar {
    margin-top: 50px;
  }
  .side_linkss > a {
    padding: 10px 0;
  }

  .socials_fullbar {
    margin: 0 15px;
  }

  .nnn_div {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .side_fullbar {
    display: none !important;
  }

  .right_fullbar {
    display: none !important;
  }

  .fullbar_main_component > hr {
    display: none;
  }

  .marquee-container {
    margin: 0 !important;
  }

  .achievements_main_div {
    margin: 0 !important;
    height: 500px;
  }

  .clients_div {
    left: 0 !important;
  }

  .cross_circle {
    background-color: transparent !important;
    top: 15px !important;
    right: 15px !important;
  }
}

.clients_div{
  margin-right: 5% !important;
}

.nnn_div {
  display: flex !important;
}

.marquee-content > span {
  font-size: 24px;
  font-weight: 600;
}

.full-screen-section {
  width: 100vw;
  height: 100vh;
  overflow: hidden; /* Ensure no vertical scroll on the section */
  position: relative;
}

.horizontal-scroll-container {
  display: flex;
  flex-wrap: nowrap; /* Ensure items stay in a single row */
  height: 100vh;
  width: 300vw; /* Width is 3 times viewport width to accommodate 3 divs */
}
.scrollable-div {
  width: 100vw;
  height: 100vh;
  flex: 0 0 auto; /* Ensure each div has fixed size */
}

.mobile_nav{
  position: fixed;
  z-index: 100000;
}

.scrolling-container {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.logos {
  display: flex;
  gap: 65px;
  animation: scroll 10s linear infinite;
  will-change: transform;
}

.client-logo {
  height: 90%;
  width: 200px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
