/* .slider_carousel {
  width: 100vh;
  transform: rotate(90deg);
}

.portfolio_top_first_half {
  color: white;
  margin-right: auto;

  padding: 100px 30px 100px 100px;
}

.portfolio_title {
  font-size: 24px;
  color: #555;
  margin: 0;
}

.portfolio_heading {
  font-size: 120px;
  margin: 0;
  font-weight: 600;
}

.portfolio_desc {
  margin: 0;
  color: #555;
}

.portfolio_bottom_section {
  color: white;
}

.portfolio_bottom_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
}

.work_with_us {
  padding: 10px;
  border: 1px solid #555;
  color: #555;
  border-radius: 20px;
}

.portfolio_bottom_heading {
  font-size: 36px;
  font-weight: 600;
  width: 40%;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .portfolio_top_first_half {
    padding: 40px 0;
    width: 100vw;
  }

  .slider_carousel{
    
  }

  .react-multi-carousel-list{
    right: -15px !important;
  }

  .portfolio_title {
    width: 80%;
    padding-left: 40px;
  }

  .portfolio_heading {
    font-size: 60px;
    padding: 20px 40px;
  }

  .randddd {
    flex-direction: column;
    max-width: 80%;
    padding-left: 40px;
  }

  .portfollio_main_inner_div {
    flex-direction: column;
  }
  .react-multi-carousel-list {
    position: relative;
    right: -30px;
    height: 400px;
    width: 300px;
  }

  .portfolio_bottom_heading {
    width: 100%;
  }

  .portfolioo_img{
    height: 400px !important;
    width: 300px !important;
  }

  .send_messages_circle{
    margin: 20px 0 !important;
  }

  .slider_carousel{
    transform: none;
    width: 80vh;
  }

  .react-multiple-carousel__arrow{
    display: none;
  }
}

.portfolioo_img {
  height: 100vh;
  width: 100vh;
  object-fit: cover;
  transform: rotate(-90deg);
} */

.portfolio_main_div {
  min-height: 100vh;
  position: relative;
  left: 69px;
  margin: 20px 5%;
}

.portfolio_banner_content {
  display: flex;
  flex-direction: column;

  width: 50%;
}

.portfolio_banner_content > * {
  color: white;
}

.portfolio_banner {
  display: flex;
  gap: 5%;
  align-items: center;
}

.portfolio_img {
  width: 40%;
}

.portfolio_img > img {
  width: 100%;
}

.portfolio_banner_content_text {
  font-size: 36px;
  font-weight: 500;
  margin: 0;
}

.port_why_choose_us {
  margin-top: 50px;
  display: flex;
  gap: 5%;
  align-items: center;
}


.port_why_choose_us>*{
  max-width: 100%;
  min-width: 40%;
}

.port_why_choose_us_img{
  max-width: 100%;
}

.port_why_choose_us_img>img{
  max-width: 100%;
}

.service_card {
  box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.093),
    0px 0px 5.3px rgba(0, 0, 0, 0.133), 0px 0px 10px rgba(0, 0, 0, 0.165),
    0px 0px 17.9px rgba(0, 0, 0, 0.197), 0px 0px 33.4px rgba(0, 0, 0, 0.237),
    0px 0px 80px rgba(0, 0, 0, 0.33);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 15px;
}

.service_card>*{
  margin: 0;
  color: white;
}

.main_services_div {
  margin-top: 50px;
  margin-right: 5%;
}

.services_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  justify-items: center;
  width: 100%;
}

.service_card > img {
  max-width: 50%;
  border-radius: 8px;
}

.service_card_title{
  font-size: 24px;
  font-weight: 600;
}

.service_card_desc{
  font-size: 16px;
  font-weight: 500;
  color: rgb(182, 177, 177);
  text-align: center;
}

.port_contact_us{
  display: flex;
  gap: 5%;
}



@media screen and (max-width:900px) {
  .services_list{
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .main_services_div{
    margin-right: 0;
  }

  .service_card{
    min-width: 250px !important;
    max-width: 250px;
  }
}

@media screen and (max-width:500px) {
  .portfolio_main_div{
    left: 0;
  }

  .port_clients_div{
    right: 0 !important;
  }

  .port_why_choose_us{
    flex-direction: column-reverse;
  }
  .services_list{
    grid-template-columns: 1fr !important;
  }

  .port_why_choose_us_img>img{
    margin-top: 15px;
  }

  .portfolio_img{
    display: none;
  }

  .portfolio_banner_content{
    width: 100%;
  }
}